import React from "react";
import { useLocation } from "react-router-dom";
import "./app.css";
import { setDominantColor } from "@store/features/colorSlice";
import { useDispatch } from "react-redux";
import Router from "./routes";
// eslint-disable-next-line
export default function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");

    const [isLoading, setIsLoading] = React.useState(location.pathname !== "/");

    dispatch(
        setDominantColor(
            localStorage.getItem("mainColor") !== null
                ? (localStorage.getItem("mainColor") as string)
                : "#323333"
        )
    );

    const fetchListUidEntityBeta = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_UUID_BETA}`);
            if (!response.ok) {
                throw new Error(
                    `Erreur lors du chargement des données: ${response.status} ${response.statusText}`
                );
            }
            const data = await response.text();

            return data
                .split("\n")
                .map((item) => item.trim())
                .filter((item) => item.length > 0);
        } catch (error) {
            // eslint-disable-next-line
            console.error(
                "Erreur lors du chargement de listUidEntity :",
                error
            );
            return [];
        }
    };

    React.useEffect(() => {
        if (uidUserEntity && process.env.REACT_APP_BETA_ACTIVE === "true") {
            fetchListUidEntityBeta().then((UidEntityBeta) => {
                const isUserBeta =
                    Array.isArray(UidEntityBeta) &&
                    UidEntityBeta.some(
                        (item: string) => item === uidUserEntity
                    );
                const redirectTo = isUserBeta
                    ? process.env.REACT_APP_BETA
                    : process.env.REACT_APP_PROD;
                if (redirectTo && redirectTo !== baseUrl) {
                    localStorage.clear();
                    const newUrl = `${redirectTo}`;
                    try {
                        window.location.replace(newUrl);
                    } catch (error) {
                        // eslint-disable-next-line
                        console.error("Erreur lors de la redirection :", error);
                    }
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [uidUserEntity]);

    return isLoading ? null : <Router />;
}
